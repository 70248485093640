


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import printJS from 'print-js';

@Component
export default class RNPResult extends Vue {
    result: any = {
      Id: '',
      No: '',
      Title: '',
      Amount: 0,
      IsPayed: false
    };
    isShowpay:boolean=false;
    paymentMethod:any= {};
    paymentMethods:any[]=[];

    getSubmitComplete (id) {
      this.$Api.regAndPay.getSubmitComplete(id).then(result => {
        this.result = result.data.ReturnValue;
        if (result.data.ReturnValue.Amount > 0 && !result.data.ReturnValue.IsPayed) {
          this.paymentMethods = result.data.ReturnValue.PaymentType;
          if (this.paymentMethods.length > 0) {
            this.paymentMethod = result.data.ReturnValue.PaymentType[0];
          }
        } else if (this.result.NextFormKey) {
          let NextFormKey = this.result.NextFormKey;
          setTimeout(() => {
            this.$router.push({ path: `/regnpay/form/${NextFormKey}` });
          }, 3000);
        }
      });
    }

    // 立即支付
    pay () {
      if (!this.paymentMethod.Id || this.paymentMethod.Id === '') {
        this.$Confirm('', this.$t('RegNPay.PlsPayWay'));
      } else {
        this.$Api.regAndPay.savePayMethod(this.paymentMethod.Id, this.result.Id).then(result => {
          if (result.data.Succeeded) {
            window.location.href = '/intimex/pay/' + this.paymentMethod.Code + '/ISRNP/' + this.result.Id;
          } else {
            this.$Confirm('', result.data.Message);
          }
        });
      }
    }

    printThis () {
      printJS('rnp-main-content', 'html');
      printJS({
        printable: 'rnp-main-content',
        type: 'html',
        css: '/static/css/print.css',
        documentTitle: this.result.Title,
        ignoreElements: ['no-print', 'printBtn'],
        scanStyles: false
      });
    }

    mounted () {
      this.getSubmitComplete(this.$route.params.id);
    }
}
